<template>
   <div class="input-group" :style="{ width: width + 'px' }">
      <input
         :class="[
            py ? `py-${py}` : 'py',
            { 'text-secondary': textSecondary },
            { 'icon-padding': icon },
            { 'form-create': createFrom },
            { 'secondary-input': secondary },
         ]"
         :placeholder="placeholder"
         aria-describedby="basic-addon1"
         aria-label="Arama"
         class="form-control error-input"
         :type="type"
         v-model="inputValue"
      />
      <span :class="{ 'input-icon d-block position-absolute': icon }">
         <component :is="icon"> </component>
      </span>
   </div>
</template>

<script>
export default {
   name: 'AmcInput',
   props: {
      value: {},
      icon: {
         type: [String, Object],
         default: '',
      },
      placeholder: {
         type: [String],
         default: '',
      },
      type: {
         type: [String],
         default: '',
      },
      textSecondary: {
         type: Boolean,
         default: false,
      },
      secondary: {
         type: Boolean,
         default: false,
      },
      py: {
         type: [Number, String],
         default: '',
      },
      createFrom: {
         type: Boolean,
         default: false,
      },
      width: {
         type: [String, Number],
      },
   },
   computed: {
      inputValue: {
         get() {
            return this.value;
         },
         set(val) {
            this.$emit('input', val);
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.input-group {
   & .secondary-input {
      background-color: #f6f6f6 !important;
   }
   & .form-control {
      border-radius: 5px !important;
      background-color: #e5efff;
      border: none;
      &:focus {
         box-shadow: none !important;
      }
   }

   & .input-icon {
      top: 9px;
      left: 11px;
      z-index: 20;
   }
}
.icon-padding {
   padding: 8.5px 5px 8.5px 45px;
}
.form-create {
   background-color: white !important;
   border: 2px solid #e6e7e9 !important;
   border-radius: 3px !important;
}
</style>
